/* eslint-disable global-require */
import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
// import { SpinnerDotted } from 'spinners-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
// import { getCookies } from '../../hooks/useCookies';
import { Editor } from '@monaco-editor/react';
import { TableContext } from '../../contexts/tableContext';
import styles from './view.module.scss';
import envValues from '../../enviornment';
import './view.css';
import 'react-quill/dist/quill.snow.css';
import 'react-toastify/dist/ReactToastify.css';

function TemplateCreate() {
  const navigate = useNavigate();
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  // const { names, setNames } = useContext(TableContext);
  // const token = `Bearer ${getCookies('Token')}`;
  const [value, setValue] = useState();
  const [nameError, setNameError] = useState();
  const [subjectError, setSubjectError] = useState();
  const [contentError, setContentError] = useState();
  const [subject, setSubject] = useState();
  const [name, setName] = useState();
  const [contentValue, setContentValue] = useState();

  const inValid = /^\S.*[a-zA-Z\s]*$/;

  const validateName = () => {
    if (!name || !inValid.test(name)) {
      setNameError('Name is required');
    } else {
      setNameError('');
    }
  };

  const validateSubject = () => {
    if (!subject || !inValid.test(subject)) {
      setSubjectError('Subject is required');
    } else {
      setSubjectError('');
    }
  };

  const validateContent = () => {
    if (!value || contentValue === 0 || !inValid.test(value)) {
      setContentError('Body is required');
    } else {
      setContentError('');
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleEditorChange = (newValue, event) => {
    setValue(newValue);
    setContentValue(newValue.length);
    if (newValue.trim() === '') {
      setContentError('Body is required');
    } else {
      setContentError('');
    }
  };

  const onSubmitData = async (e) => {
    e.preventDefault();
    if (!name || !subject || !value) {
      validateName();
      validateSubject();
      validateContent();
    } else {
      const apiData = {
        template_name: name,
        template_subject: subject,
        template_body: value,
      };
      const res = await axios.post(`${envValues.REACT_APP_API_ENDPOINT}/mailtemplates`, apiData);
      if (res.data.success) {
        navigate(-1);
        toast.success('Template creation successfull');
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Create Template');
  }, []);

  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ height: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.empdetailssection}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          <Container fluid>
            <div className="content">
              <form onSubmit={onSubmitData}>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card-box task-detail">
                        <ul className="list-inline task-dates m-b-0 mt-5">
                          <li className="">
                            <h5 className="m-b-5 mx-1">Template Name</h5>
                            <div className="form-group mx-1">
                              <input
                                className="form-control"
                                name="name"
                                //  eslint-disable-next-line
                                onKeyUp={validateName}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                isRequired
                                mandatory
                                label="name"
                              />
                            </div>
                            {nameError && <small className="text-danger">{nameError}</small>}
                          </li>
                          <li>
                            <h5 className="m-b-5 mx-1">Template Subject</h5>
                            <div className="form-group mx-1">
                              <input
                                className="form-control"
                                name="subject"
                                //  eslint-disable-next-line
                                isRequired
                                onKeyUp={validateSubject}
                                mandatory
                                label="subject"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                              />
                            </div>
                            {subjectError && <small className="text-danger">{subjectError}</small>}
                          </li>
                        </ul>

                        <div className="clearfix" />
                      </div>
                    </div>
                    {/* end col */}
                    {/* end col */}
                    <div className="col-lg-12">
                      <h5 className="m-b-5 mx-4">Template Body</h5>
                      <Editor
                        height="350px"
                        language="html"
                        theme="vs-light"
                        value={value}
                        onKeyUp={validateContent}
                        onChange={handleEditorChange}
                        options={{
                          inlineSuggest: true,
                          fontSize: '12px',
                          formatOnType: true,
                          autoClosingBrackets: true,
                          minimap: {
                            enabled: false,
                          },
                        }}
                      />
                    </div>
                    {contentError && <small className="text-danger">{contentError}</small>}
                  </div>
                  <div className="cointainer text-center m-5">
                    <Button type="submit" variant="success">
                      Submit
                    </Button>{' '}
                    {/* end row */}
                  </div>
                </div>
              </form>
              {/* container */}
            </div>
          </Container>
        </div>
        {/* <ToastContainer autoClose={2000} /> */}
      </div>
    </div>
  );
}

export default TemplateCreate;
