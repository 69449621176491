import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { Entry } from '../../redux/entrySlice';
import styles from './editErnieTv.module.scss';
import { TableContext } from '../../contexts/tableContext';
import 'react-toastify/dist/ReactToastify.css';
import TextAreaField from '../../components/TextArea/TextArea';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import { REACT_APP_API_ROLES_DROPDOWN } from '../../redux/apiConstants';
import Loader from '../../components/Loader/Loader';

function EditErnieTv() {
  let userId;
  const [submiting, setSubmitting] = useState(false);
  const { dashboardStyle } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const params = useParams();
  const [user, setUser] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [roles, setRoles] = useState([]);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Edit Ernie Tv');
    userId = params.ernieTvId;
    const url2 = REACT_APP_API_ROLES_DROPDOWN; // api url
    fetch(url2)
      .then((resp) => resp.json()) // calling url by method GET
      .then((resp) => {
        setRoles(resp.data.role);
      }); // setting response to state posts

    const actionData = {};
    actionData.actionUrl = `ernietv/${userId}`;
    actionData.actionMethod = 'get';
    dispatch(Entry(actionData)).then((resp) => {
      setUser(resp.payload.data.ernieTv);
      reset(resp.payload.data.ernieTv);
    });
  }, []);
  // Function to extract the field causing the error from the error message
  const extractErrorField = (errorMessage) => {
    const startIndex = errorMessage.indexOf('index: ') + 7;
    const endIndex = errorMessage.indexOf(' dup key');
    const fieldName = errorMessage.substring(startIndex, endIndex).trim();
    return fieldName;
  };
  // eslint-disable-next-line no-unused-vars
  function onSubmitdataZone(data) {
    userId = params.ernieTvId;
    if (data.title.trim().length === 0) {
      document.getElementById('name').focus();
    } else {
      setSubmitting(true);
      setError(null);
      const apiData = {
        title: data.title,
      };
      data.actionUrl = `ernietv/${userId}`;
      data.actionMethod = 'patch';
      data.apiData = apiData;
      dispatch(Entry(data))
        .then((resp) => {
          setSubmitting(false);
          if (resp.payload.code === 200) {
            navigate(-1);
            toast.success('Ernie TV video updated successfully.');
            // eslint-disable-next-line max-len
          } else if (
            resp.payload.code === 401 ||
            resp.payload.code === 400 ||
            resp.payload.code === 403
          ) {
            setError(resp.payload.message);
            toast.error('Error');
          } else {
            navigate(-1);
          }
        })
        .catch((axiosError) => {
          setSubmitting(false);
          if (axiosError.response && axiosError.response.status === 500) {
            // Extract the field causing the error from the error message
            const errorMessage = extractErrorField(axiosError.response.data.message);
            // Display the user-friendly error message to the user
            setError(
              `The ${
                errorMessage.includes('email') ? 'Email' : errorMessage
              } you provided is already in use. Please use a different ${
                errorMessage.includes('email') ? 'Email' : errorMessage
              }.`
            );
          } else {
            setError('An unexpected error occurred. Please try again later.');
          }
        });
    }
  }
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          <div className="container-fluid mt-5 edituserform">
            {user.length !== 0 ? (
              <form onSubmit={handleSubmit(onSubmitdataZone)}>
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    {user && (
                      <div>
                        <TextAreaField
                          className={styles.inputbox}
                          classNamedark={styles.inputbox1}
                          labelstyle={styles.formlabel}
                          label="Video Title"
                          fieldName="title"
                          placeHolder="Enter title"
                          register={register}
                          errors={errors}
                          isRequired
                          mandatory
                          minimLength={3}
                          maximLength={300}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className={styles.ernietv_video}>
                      {/* <img src={user.video_thumbnail} alt="thumbnail" /> */}
                      <VideoPlayer
                        autoplay={false}
                        thumbnail={user.video_thumbnail}
                        videoSrc={user.output_video}
                        label="Ernie TV"
                        fieldName="Ernie TV"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? 'Please wait..' : 'Update'}
                />
                {error && <h6 className={styles[errormsgStyle]}>{error}</h6>}
              </form>
            ) : (
              <Loader />
            )}
            <br />
            <ToastContainer autoClose={2000} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditErnieTv;
