import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { getCookies } from '../../hooks/useCookies';
import styles from './ToggleButton.module.scss';
import envValues from '../../enviornment';

function ToggleButtonOnOff({ value, id, section }) {
  const [hide, setHide] = useState();
  const [isOff, setIsOff] = useState(false);

  useEffect(() => {
    setIsOff(value);
    setHide(value);
  }, [value]);

  const handleButtonClick = () => {
    confirmAlert({
      title: 'Confirm to change status',
      message: 'Are you sure want to change status.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setIsOff(!isOff);
            const headers = {
              'Content-Type': 'application/json; charset=utf-8',
              'Access-Control-Allow-Origin': '*',
            };
            const body = { token: getCookies('Token'), userid: id, active: !isOff };
            axios
              .post(`${envValues.ERNIE_API_ENDPOINT}/users/toggle-change`, body, headers)
              .then(() => {});
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };
  return (
    <div className="col ">
      {section !== 'reportedposts' ? (
        <label className={styles.switch}>
          <input type="checkbox" checked={isOff} onClick={handleButtonClick} />
          <span className={styles.slider} />
        </label>
      ) : (
        <p>{hide === 0 ? <p>Hidden</p> : <p>Shown</p>}</p>
      )}
    </div>
  );
}

ToggleButtonOnOff.propTypes = {
  value: PropTypes.bool,
  id: PropTypes.string,
  section: PropTypes.string,
};
ToggleButtonOnOff.defaultProps = {
  value: false,
  id: null,
  section: null,
};
export default ToggleButtonOnOff;
