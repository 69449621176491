/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { TableContext } from '../../contexts/tableContext';
// import { SpinnerRoundFilled } from 'spinners-react';
import ActionButtons from '../ActionsButtons/ActionButtons';
import Pagination from '../Pagination/Pagination';
import { tabledelete } from '../../redux/TableDeleteSlice';
import ToggleButtonOnOff from '../ToggleButton/ToggleButton';
// import ActionSelection from '../ActionsButtons/ActionSelection';
import styles from './CustomTable.module.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { getCookies } from '../../hooks/useCookies';
// import Loader from '../Loader/Loader';

const CustomTable = React.memo(({ urlParam, viewPopUp, toggleButton, section, populateItems }) => {
  let url;
  const { dashboardStyle } = useContext(TableContext);
  const navigate = useNavigate();
  const { bodyStyle } = useContext(TableContext);
  const [addButtonPermissions, setAddButtonPermissions] = useState({});
  const { searchboxStyle } = useContext(TableContext);
  const { tableheadStyle } = useContext(TableContext);
  const { socketUpdate } = useContext(TableContext);
  const { tablebodyStyle, setSpinLoader } = useContext(TableContext);
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  const { pageNumView, setPageNumView, deletedData } = useContext(TableContext);
  // new
  const tablelength = tableData.length;
  const [checkedState, setCheckedState] = useState(new Array(tablelength).fill(false));
  const [checkedAll, setCheckedAll] = useState(false);
  const token = `Bearer ${getCookies('Token')}`;
  const [sorting, setSorting] = useState([{ col: 'none', sortType: 'none' }]);
  const [paginationData, setPaginationData] = useState([
    { page: 0, totalResult: 0, totalPages: 0 },
  ]);
  const [search, setSearchTerm] = useState(null);
  const [timer, setTimer] = useState(null);
  const [columns, setColumns] = useState([]);
  const [deleteSelectedData, setDeletedData] = useState(null);
  const [selected, setselected] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [pageNum, setPageNum] = useState(1);
  let dataPerPage = 5;
  let response;
  let statusCode;
  const handleOnChange = (id, position) => {
    // eslint-disable-next-line max-len
    // eslint-disable-next-line max-len
    const updatedCheckedState = checkedState.map((item, indexed) =>
      indexed === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
    setselected((oldArray) => [...oldArray, id]);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= checkedState.length; i++) {
      if (checkedState[position] === true) {
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < selected.length; j++) {
          if (selected[j] === id) {
            selected.splice(j, 1);
          }
          // const newselected = selected.filter((item) => item === id);
          // setselected(newselected);
        }
      }
    }
  };
  const actionAdd = () => {
    navigate(`/${section.toLowerCase()}/create/`);
  };
  const handleOnChangeAll = () => {
    setCheckedAll(!checkedAll);
    const newarray = [];
    if (checkedAll === false) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < tableData.length; i++) {
        newarray.push(tableData[i].id);
        checkedState[i] = true;
      }
      setselected(newarray);
    } else {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < tableData.length; i++) {
        checkedState[i] = false;
      }
      setselected([]);
    }
  };
  const getUsers = async () => {
    setTableLoading(true);
    if (section === 'User') {
      dataPerPage = 50;
    }
    url = urlParam;
    url += `page=${pageNumView > 1 ? pageNumView : pageNum}&limit=${dataPerPage}`;
    if (populateItems) url += `&populate=${populateItems}`;
    if (sorting.sortType && sorting.sortType !== 'none') {
      url += `&sortBy=${sorting.col}:${sorting.sortType}`;
    }
    if (search !== null) {
      url += `&searchBy=${search}`;
    }
    axios
      .get(url, {
        method: 'GET',
        headers: { Authorization: token },
      })
      .then((resp) => {
        setTableLoading(false);
        statusCode = resp.status;
        return resp;
      })
      .then((res) => {
        if (statusCode === 200) {
          response = res.data.data.result;
          if (section === 'User') {
            const startOrderNumber = (pageNum - 1) * dataPerPage + 1;
            const resultsWithOrder = response.results.map((item, index) => ({
              ...item,
              'SERIAL NO': startOrderNumber + index,
            }));
            setTableData(resultsWithOrder);
            response.Displayfields.unshift('SERIAL NO');
            window.scrollTo({ top: 0, behavior: 'smooth' });
          } else {
            setTableData(response.results);
          }
          setPaginationData({
            page: response.page,
            totalResult: response.totalResults,
            totalPages: response.totalPages,
          });
          setColumns(response.Displayfields);
          setSpinLoader(false);
        }
      })
      .catch((err) => {
        setTableLoading(false);
        return err;
      });
  };
  useEffect(() => {
    setSearchTerm(null);
    setPageNumView(1);
  }, []);
  useEffect(() => {
    const temp = section.toLowerCase();
    // const temp = section.replace(/\s+/g, '').toLowerCase();
    const permissions = getCookies('USERPERMISSION');
    const lowercaseTemp = temp.toLowerCase();
    let matchFound = false;

    for (let i = 0; i < permissions.length; i += 1) {
      const val = permissions[i];

      if (val.section.toLowerCase() === lowercaseTemp) {
        setAddButtonPermissions({
          view: val.view || false,
          edit: val.edit || false,
          create: val.create || false,
          delete: val.delete || false,
          list: !!(val.view || val.edit || val.create || val.delete),
        });

        matchFound = true;
        break;
      } else if (val.submenu) {
        for (let j = 0; j < val.submenu.length; j += 1) {
          const element = val.submenu[j];

          if (element.section.toLowerCase() === lowercaseTemp) {
            setAddButtonPermissions({
              view: element.view || false,
              edit: element.edit || false,
              create: element.create || false,
              delete: element.delete || false,
              list: !!(element.view || element.edit || element.create || element.delete),
            });

            matchFound = true;
            break;
          }
        }

        if (matchFound) {
          break;
        }
      }
    }
    getUsers();
    const newarray = [];
    if (checkedAll) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < tableData.length; i++) {
        newarray.push(tableData[i].id);
        checkedState[i] = true;
      }
      setselected(newarray);
    } else {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < tableData.length; i++) {
        checkedState[i] = false;
      }
      setselected([]);
    }
  }, [pageNum, search, sorting, deletedData, socketUpdate]);
  const setSort = (param) => () => {
    if (param === 'Zone Name' && section === 'reportedzones') {
      param = 'zone_name';
    } else if (param === 'Zone Name' && (section === 'ernieofficial' || section === 'fanzone')) {
      param = 'name';
    } else if (param === 'Zone Owner' && section === 'reportedzones') {
      param = 'zone_owner';
    } else if (param === 'Zone Owner' && (section === 'ernieofficial' || section === 'fanzone')) {
      param = 'owner_name';
    } else if (param === 'Zone Channel' && section === 'reportedzones') {
      param = 'zone_channel';
    } else if (param === 'Zone Channel' && (section === 'ernieofficial' || section === 'fanzone')) {
      param = 'channel_name';
    } else if (param === 'Zone League' && section === 'reportedzones') {
      param = 'zone_league';
    } else if (param === 'Zone League' && (section === 'ernieofficial' || section === 'fanzone')) {
      param = 'league';
    } else if (param === 'reported count') {
      param = 'reported_count';
    }
    setPageNum(1);
    switch (sorting.sortType) {
      case 'none':
        setSorting({ col: param, sortType: 'asc' });
        break;
      case 'asc':
        setSorting({ col: param, sortType: 'desc' });
        break;
      case 'desc':
        setSorting({ col: param, sortType: 'none' });
        break;
      default:
        setSorting({ col: param, sortType: 'asc' });
        break;
    }
  };
  const onFilterTextChange = (e) => {
    const even = e;
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        const { value } = even.target;
        if (value.length > 0) {
          setPageNum(1);
          setSearchTerm(even.target.value);
        } else {
          setSearchTerm(null);
        }
      }, 1000)
    );
  };
  return (
    <div className={styles[bodyStyle]}>
      {/* {!addButtonPermissions.list && <Loader />} */}
      {addButtonPermissions.list && (
        <div
          className={styles[dashboardStyle]}
          style={{ minHeight: '100vh' }}
          id={styles.dashboardcont2}
        >
          <div className="container-fluid" id={styles.container}>
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12">
                {section !== 'reportedposts' && (
                  <div className={styles.search_box}>
                    <input
                      className={styles[searchboxStyle]}
                      id={styles.searchbar}
                      type="search"
                      onChange={onFilterTextChange}
                      placeholder="Search"
                      // value={search}
                      readOnly={tableLoading}
                    />
                    <div
                      className={tableLoading ? 'spinner-border spinner-border-sm' : ''}
                      id={styles.spinner}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12" />
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className={styles.sectionright}>
                  {addButtonPermissions.delete && selected.length > 0 && (
                    <div className="dropdown" id={styles.dropdown}>
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Actions
                      </button>
                      <ul
                        className="dropdown-menu"
                        id={styles.dropdown_menu}
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              confirmAlert({
                                title: '',
                                message: 'Are you sure, you want to delete.',
                                buttons: [
                                  {
                                    label: 'Yes',
                                    onClick: () => {
                                      dispatch(tabledelete(selected)).then(() => {
                                        setDeletedData(`data deleted ${selected}`);
                                        window.location.reload(false);
                                      });
                                    },
                                  },
                                  {
                                    label: 'No',
                                    // onClick: () => alert("Click No")
                                  },
                                ],
                              });
                            }}
                          >
                            Delete{' '}
                            <i className="fa fa-trash" aria-hidden="true" id={styles.del_icon} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                  {addButtonPermissions.create && (
                    <button
                      className={styles.tablebtn}
                      type="button"
                      onClick={() => {
                        actionAdd();
                      }}
                    >
                      {' '}
                      <FontAwesomeIcon icon={faPlus} className={styles.add_icon} />
                      Add
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="table-responsive" style={{ minHeight: '250px' }}>
              <table className="table table-borderless">
                {columns.length > 0 && (
                  <thead>
                    <tr className={styles[tableheadStyle]}>
                      {columns.map((item) => (
                        <th scope="col">
                          {' '}
                          {item.toUpperCase().replace(/_/g, ' ')}
                          {item.toUpperCase() !== 'POST' && (
                            <button
                              className={styles.sortbtn}
                              type="button"
                              aria-label="Sort button"
                              onClick={setSort(item)}
                            >
                              {sorting.sortType === 'asc' && sorting.col === item ? (
                                <i className="fa fa-arrow-up" />
                              ) : sorting.sortType === 'desc' && sorting.col === item ? (
                                <i className="fa fa-arrow-down" />
                              ) : (
                                <i className="fa fa-sort" />
                              )}
                            </button>
                          )}
                        </th>
                      ))}
                      <th scope="col">ACTIONS</th>
                    </tr>
                  </thead>
                )}
                {Object.keys(tableData) && !tableLoading ? (
                  <tbody>
                    {tableData.map((item, index) => (
                      <tr className={styles[tablebodyStyle]}>
                        {columns.map((ele) => (
                          <td>
                            {ele === toggleButton.field ? (
                              <ToggleButtonOnOff
                                value={item[ele]}
                                id={item.id}
                                section={section}
                                placeholders={['Active', 'Inactive']}
                              />
                            ) : typeof item[ele] === 'boolean' ? (
                              <h6 className="order_date"> String(item[ele])</h6>
                            ) : ele === 'post' ? (
                              item.urlResult ? (
                                <button
                                  className={styles.view_post}
                                  type="button"
                                  onClick={() => window.open(item.urlResult, '_blank')}
                                >
                                  View Post
                                </button>
                              ) : (
                                <p> NA</p>
                              )
                            ) : ele === 'Zone Name' && section === 'reportedzones' ? (
                              <p>{item?.zone_id?.name}</p>
                            ) : ele === 'Zone Name' &&
                              (section === 'ernieofficial' || section === 'fanzone') ? (
                              <p>{item?.name}</p>
                            ) : ele === 'reported count' &&
                              (section === 'reportedzones' || section === 'reportedposts') ? (
                              <p>{item?.reported_count}</p>
                            ) : ele === 'Zone Owner' && section === 'reportedzones' ? (
                              <p>{item?.zone_id?.owner_name}</p>
                            ) : ele === 'Zone Owner' &&
                              (section === 'ernieofficial' || section === 'fanzone') ? (
                              <p>{item?.owner_name}</p>
                            ) : ele === 'Zone League' && section === 'reportedzones' ? (
                              <p>{item?.zone_league?.toUpperCase()}</p>
                            ) : ele === 'Zone League' &&
                              (section === 'ernieofficial' || section === 'fanzone') ? (
                              <p>{item?.league?.toUpperCase()}</p>
                            ) : ele === 'Zone Channel' && section === 'reportedzones' ? (
                              <p>{item?.zone_channel}</p>
                            ) : ele === 'Zone Channel' &&
                              (section === 'ernieofficial' || section === 'fanzone') ? (
                              <p>{item?.channel_name}</p>
                            ) : ele === 'video_thumbnail' && section === 'ernietv' ? (
                              <img
                                src={item?.video_thumbnail}
                                alt="thumbnail"
                                width={70}
                                height={70}
                              />
                            ) : (
                              item[ele]
                            )}
                          </td>
                        ))}
                        <td>
                          <ActionButtons
                            data={item}
                            viewPopUp={viewPopUp}
                            section={section}
                            pageNum={pageNum}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  ''
                )}
                {tableData.length === 0 && !tableLoading ? (
                  <h6 className={styles.norecord}>No record found</h6>
                ) : (
                  ''
                )}
              </table>
            </div>
            {tableLoading ? (
              ''
            ) : (
              <Pagination paginationData={paginationData} setPageNum={setPageNum} />
            )}
            <br />
            <br />
          </div>
        </div>
      )}
    </div>
  );
});
CustomTable.propTypes = {
  urlParam: PropTypes.string,
  viewPopUp: PropTypes.bool,
  toggleButton: PropTypes.oneOfType([PropTypes.object]),
  section: PropTypes.string,
  populateItems: PropTypes.string,
};
CustomTable.defaultProps = {
  viewPopUp: false,
  toggleButton: { show: false, field: 'none' },
  section: 'User',
  urlParam: null,
  populateItems: false,
};
export default CustomTable;
