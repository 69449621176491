import React, { useEffect, useContext } from 'react';
import { TableContext } from '../../contexts/tableContext';
import { REACT_APP_API_FETCH_USER } from '../../redux/apiConstants';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import styles from './index.module.scss';
import CustomTable from '../../components/CustomTable/CustomTable';
import Loader from '../../components/Loader/Loader';

export default function UserIndex() {
  const populate = 'roles_id';
  const { setDashboardHeader } = useContext(TableContext);
  const { spinLoader, setSpinLoader } = useContext(TableContext);
  useEffect(() => {
    setSpinLoader(true);
    window.scrollTo(0, 0);
    setDashboardHeader('Users');
  }, []);
  return (
    <div className={styles.indexdiv}>
      {spinLoader && <Loader />}
      <CustomTable
        urlParam={REACT_APP_API_FETCH_USER}
        // viewPopUp
        section="User"
        toggleButton={{ show: true, field: 'active' }}
        deleteCondition={{ checkCondition: false, apiUrl: 'www.google.com' }}
        populateItems={populate}
      />
    </div>
  );
}
