/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext, useRef } from 'react';
import axios from 'axios';
import AWS from 'aws-sdk';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate, Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { TableContext } from '../../contexts/tableContext';
import styles from './createErnietv.module.scss';
import {
  REACT_APP_API_ROLES_DROPDOWN,
  REACT_APP_API_ERNIE_TV,
  REACT_APP_API_FETCH_SIGNED_URL,
} from '../../redux/apiConstants';
import ProgressVar from '../../components/ProgressBar/ProgressBar';
import axiosInterceptor from '../../utilityFunctions/interceptor';

function CreateErnietv() {
  const { dashboardStyle } = useContext(TableContext);
  const [submiting, setSubmitting] = useState(false);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [perror, setPerror] = useState(null);
  const [loader, setLoader] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState('');
  const [videoName, setVideoName] = useState('');
  const [videoPreview, setVideoPreview] = useState('');
  const [videoWidth, setVideoWidth] = useState('');
  const [videoHeight, setVideoHeight] = useState('');
  const [signedUrl, setSignedUrl] = useState('');
  const [originalVideoName, setOriginalVideoName] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  const [videoKey, setVideoKey] = useState('');
  const [progress, setProgress] = useState(0);
  const MAX_FILE_SIZE = 400 * 1024 * 1024; // 400 MB in bytes
  const fileInputRef = useRef(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Add Video');
    const url = REACT_APP_API_ROLES_DROPDOWN;
    fetch(url).then((resp) => resp.json());
  }, [reset]);

  const createNewErnieTv = async (fileBody) => {
    await axios.post(REACT_APP_API_ERNIE_TV, fileBody).then((resp3) => {
      if (resp3.data.code === 200) {
        navigate(-1);
        toast.success(
          'Video conversion started successfully, and we will notify you once it is ready!',
          {
            autoClose: 6000,
          }
        );
      } else if (
        resp3.payload.code === 401 ||
        resp3.payload.code === 400 ||
        resp3.payload.code === 409
      ) {
        setError(resp3.payload.message);
        setSubmitting(false);
      } else {
        setError('Something went wrong!');
        setSubmitting(false);
      }
    });
  };

  const createNewErnieVideo = async (e) => {
    e.preventDefault();
    setError(null);
    if (!selectedVideo && !videoName) {
      // eslint-disable-next-line no-alert
      setSubmitting(false);
      setError('Please enter the video title and select a file.');
      e.preventDefault();
    } else if (!selectedVideo) {
      setSubmitting(false);
      setError('Please select a file.');
      e.preventDefault();
    } else if (!videoName) {
      setSubmitting(false);
      setError('Please enter the video title.');
      e.preventDefault();
    } else if (selectedVideo && videoName) {
      setSubmitting(true);
      try {
        await axiosInterceptor
          .post(REACT_APP_API_FETCH_SIGNED_URL, {
            key: originalVideoName.replace(/\s+/g, ''),
          })
          .then(async (resp) => {
            if (resp.data.code === 200) {
              setSignedUrl(resp.data.data.result.uploadUrl);
              setVideoKey(resp.data.data.result.key);
              setBaseUrl(resp.data.data.result.baseUrl);
              await axiosInterceptor
                .put(resp.data.data.result.uploadUrl, selectedVideo, {
                  headers: {
                    'Content-Type': 'video/*',
                  },
                  onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setProgress(percentCompleted);
                  },
                })
                .then(async (resp1) => {
                  const fileBody = {
                    video_url: resp.data.data.result.baseUrl,
                    video_name: resp.data.data.result.key,
                    title: videoName,
                    videoHeight,
                    videoWidth,
                  };
                  await createNewErnieTv(fileBody);
                });
            } else {
              setError('Something went wrong while fetching signed url!');
              setSubmitting(false);
            }
          });
      } catch (err1) {
        setSubmitting(false);
        setError('Something went wrong. Please try again!');
      }
    } else {
      setError('Something went wrong. Please try again!');
      setSubmitting(false);
    }
  };

  const handleVideoPreview = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setError('File size exceeds the maximum limit of 400 MB');
      } else {
        setError('');
        setSelectedVideo(file);
        setVideoPreview(URL.createObjectURL(file));
        setOriginalVideoName(file.name);
      }
    }
  };

  const handleRemoveFile = () => {
    fileInputRef.current.value = '';
    reset();
    setSubmitting(false);
    setVideoPreview('');
    setSelectedVideo('');
    setVideoName('');
    setPerror(null);
    setError(null);
    setProgress(0);
  };

  const removeSelectedVideo = () => {
    fileInputRef.current.value = '';
    setVideoPreview('');
    setSelectedVideo('');
  };

  useEffect(() => {
    const videoElement = document.createElement('video');
    videoElement.src = videoPreview;
    videoElement.onloadedmetadata = () => {
      setVideoHeight(videoElement.videoHeight);
      setVideoWidth(videoElement.videoWidth);
    };
  }, [videoPreview]);

  return (
    <div className={styles[bodyStyle]}>
      {loader && (
        <div className={styles.overlay} id="overlay">
          <div className={styles.spinner_center}>
            <span className={styles.circle_spinner} />
          </div>
        </div>
      )}
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          <div className="container-fluid mt-5">
            <form>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <p>Enter Title</p>
                  <textarea
                    rows="7"
                    cols="80"
                    value={videoName}
                    maxLength={300}
                    minLength={3}
                    onChange={(e) => setVideoName(e.target.value)}
                  />
                  {videoName.length !== 0 && (
                    <p style={{ float: 'right', fontSize: '12px' }}>
                      {'Characters Left : '}
                      {300 - videoName.length}
                    </p>
                  )}
                  {error && (
                    <h6 className={styles[errormsgStyle]} style={{ float: 'left' }}>
                      {error}
                    </h6>
                  )}
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 pt-5 ernietvupload">
                  <input
                    type="file"
                    accept="video/*"
                    onChange={handleVideoPreview}
                    ref={fileInputRef}
                  />
                  <div className="video_outer">
                    {selectedVideo && (
                      <button type="button" onClick={removeSelectedVideo}>
                        x
                      </button>
                    )}

                    {selectedVideo && (
                      // eslint-disable-next-line jsx-a11y/media-has-caption
                      <video controls width="400" key={videoPreview}>
                        <source src={videoPreview} type="video/mp4" />
                      </video>
                    )}
                  </div>
                </div>
              </div>
              <br />
              {progress > 0 && <ProgressVar percentage={progress} />}
              <br />
              <div className="d-flex">
                <input
                  className={styles.formbtn}
                  type="button"
                  disabled={submiting}
                  value={submiting ? 'Please wait..' : 'Submit'}
                  onClick={createNewErnieVideo}
                />
                <input
                  disabled={submiting}
                  className={styles.resetbtn}
                  type="button"
                  value="Reset"
                  onClick={handleRemoveFile}
                />
              </div>
              <br />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateErnietv;
