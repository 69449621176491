import { React, lazy } from 'react';
import { Outlet } from 'react-router-dom';

const Header = lazy(() => import('../components/Header/Header'));
const Footer = lazy(() => import('../components/Footer/DashFooter'));
const Menu = lazy(() => import('../components/LeftMenu/DashMenu'));
export default function Innerlayout() {
  return (
    <div>
      <Header />
      <Menu />
      <div>
        <Outlet />{' '}
      </div>
      <Footer />
    </div>
  );
}
