/* eslint-disable global-require */
import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { getCookies } from '../../hooks/useCookies';
import { TableContext } from '../../contexts/tableContext';
import styles from './viewErnietv.module.scss';
import envValues from '../../enviornment';
import Loader from '../../components/Loader/Loader';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';

function ViewErnieTv() {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { viewformStyle } = useContext(TableContext);
  const params = useParams();
  let zoneId;
  const token = `Bearer ${getCookies('Token')}`;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  let statusCode;
  const getUserDetails = async () => {
    setLoading(true);
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/ernietv/${zoneId}`, {
        method: 'GET',
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          setDetails(res.data.ernieTv);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    zoneId = params.ernieTvId;
    getUserDetails();
    setDashboardHeader('Ernie TV Details');
  }, []);
  return (
    <div className={styles[bodyStyle]}>
      {loading ? (
        <Loader />
      ) : (
        <div
          className={styles[dashboardStyle]}
          style={{ height: '100vh' }}
          id={styles.dashboardcont2}
        >
          <div className={styles[formthemeStyle]} id={styles.addform}>
            <Container fluid>
              <FontAwesomeIcon
                icon={faArrowLeft}
                value="Back"
                onClick={() => {
                  navigate(-1);
                }}
                className={styles.arrowback}
              />
              <Row className="mt-3">
                <Col lg={12} md={12} sm={12}>
                  <div className={styles.detaildiv}>
                    <Row>
                      <Col lg={6}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Video Title</span>
                          <p className={styles.empname}>{details.title}</p>
                        </div>
                      </Col>
                      <Col lg={6} className={styles.ernietv_video}>
                        <VideoPlayer
                          autoplay={false}
                          thumbnail={details.video_thumbnail}
                          videoSrc={details.output_video}
                          label="Ernie TV"
                          fieldName="Ernie TV"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewErnieTv;
