import React from 'react';
import PropTypes from 'prop-types';
import 'video.js/dist/video-js.css';
import ReactHlsPlayer from 'react-hls-player';

function VideoPlayer({ thumbnail, videoSrc, label, labeldarkstyle, fieldName, labelstyle }) {
  let classNames = labelstyle;

  if (localStorage.getItem('theme') === 'dark') {
    classNames = labeldarkstyle;
  }
  return (
    <div className="form-field">
      <label htmlFor={fieldName} className={classNames ?? labelstyle}>
        {label}
      </label>
      <ReactHlsPlayer
        src={videoSrc}
        poster={thumbnail}
        autoPlay={false}
        controls
        width="100%"
        height="auto"
      />
    </div>
  );
}

VideoPlayer.propTypes = {
  label: PropTypes.string,
  fieldName: PropTypes.string,
  labelstyle: PropTypes.string,
  thumbnail: PropTypes.string.isRequired,
  videoSrc: PropTypes.string.isRequired,
  labeldarkstyle: PropTypes.element,
};

VideoPlayer.defaultProps = {
  label: 'Ernie TV',
  fieldName: 'Ernie TV',
  labelstyle: 'formlabel',
  labeldarkstyle: null,
};

export default VideoPlayer;
