/* eslint-disable global-require */
import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { Button, Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Editor } from '@monaco-editor/react';
// import ReactQuill from 'react-quill';
import { SpinnerDotted } from 'spinners-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { getCookies } from '../../hooks/useCookies';
import { TableContext } from '../../contexts/tableContext';
import styles from './view.module.scss';
import envValues from '../../enviornment';
import './view.css';
import 'react-quill/dist/quill.snow.css';
import 'react-toastify/dist/ReactToastify.css';

function EditTemplate() {
  const navigate = useNavigate();
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  let templateId;
  const params = useParams();
  const token = `Bearer ${getCookies('Token')}`;
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState();
  const [nameError, setNameError] = useState();
  const [subjectError, setSubjectError] = useState();
  const [contentError, setContentError] = useState();
  const [subject, setSubject] = useState();
  const [name, setName] = useState();
  const [contentValue, setContentValue] = useState();
  const [tempId, setTempId] = useState();

  const inValid = /^\S.*[a-zA-Z\s]*$/;

  let statusCode;
  const getCmsDetails = async () => {
    setLoading(true);
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/mailtemplates/${templateId}`, {
        method: 'GET',
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp;
      })
      .then((res) => {
        if (statusCode === 200) {
          setName(res.data.data.template?.template_name);
          setSubject(res.data.data.template?.template_subject);
          setValue(res.data.data.template?.template_body);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  const validateName = () => {
    if (!name || !inValid.test(name)) {
      setNameError('Name is required');
    } else {
      setNameError('');
    }
  };

  const validateSubject = () => {
    if (!subject || !inValid.test(subject)) {
      setSubjectError('Subject is required');
    } else {
      setSubjectError('');
    }
  };
  const validateContent = () => {
    if (!value || contentValue === 0 || !inValid.test(value)) {
      setContentError('Body is required');
    } else {
      setContentError('');
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleEditorChange = (newValue, event) => {
    setValue(newValue);
    setContentValue(newValue.length);
    if (newValue.trim() === '') {
      setContentError('Body is required');
    } else {
      setContentError('');
    }
  };

  const onSubmitData = async (e) => {
    e.preventDefault();
    if (nameError || subjectError || contentError) {
      toast('Fill all the fields');
    } else {
      const apiData = {
        template_name: name,
        template_subject: subject,
        template_body: value,
      };
      const res = await axios.patch(
        `${envValues.REACT_APP_API_ENDPOINT}/mailtemplates/${tempId}`,
        apiData
      );
      if (res.data.success) {
        navigate(-1);
        toast.success('Template updation successfull');
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    templateId = params.templateId;
    setTempId(params.templateId);
    getCmsDetails();
    setDashboardHeader('Edit Template');
  }, []);

  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ height: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.empdetailssection}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          {loading ? (
            <SpinnerDotted
              style={{
                left: '50%',
                position: 'relative',
                textAlign: 'center',
                top: '50%',
                color: '#fe0001',
              }}
            />
          ) : (
            <Container fluid>
              <div className="content">
                <form onSubmit={onSubmitData}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card-box task-detail">
                          <ul className="list-inline task-dates m-b-0 mt-5">
                            <li className="">
                              <h5 className="m-b-5 mx-1">Template Name</h5>
                              <div className="form-group mx-1">
                                <input
                                  className="form-control"
                                  name="name"
                                  //  eslint-disable-next-line
                                  onKeyUp={validateName}
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  isRequired
                                  mandatory
                                  label="name"
                                />
                              </div>
                              {nameError && <small className="text-danger">{nameError}</small>}
                            </li>
                            <li>
                              <h5 className="m-b-5 mx-1">Template Subject</h5>
                              <div className="form-group mx-1">
                                <input
                                  className="form-control"
                                  name="subject"
                                  //  eslint-disable-next-line
                                  isRequired
                                  onKeyUp={validateSubject}
                                  mandatory
                                  label="subject"
                                  value={subject}
                                  onChange={(e) => setSubject(e.target.value)}
                                />
                              </div>
                              {subjectError && (
                                <small className="text-danger">{subjectError}</small>
                              )}
                            </li>
                          </ul>

                          <div className="clearfix" />
                        </div>
                      </div>
                      {/* end col */}
                      {/* end col */}
                      <div className="col-lg-12">
                        <h5 className="m-b-5 mx-4">Template Body</h5>
                        <Editor
                          height="350px"
                          language="html"
                          theme="vs-light"
                          value={value}
                          onKeyUp={validateContent}
                          onChange={handleEditorChange}
                          options={{
                            inlineSuggest: true,
                            fontSize: '12px',
                            formatOnType: true,
                            autoClosingBrackets: true,
                            minimap: {
                              enabled: false,
                            },
                          }}
                        />
                      </div>
                      {contentError && <small className="text-danger">{contentError}</small>}
                    </div>
                    <div className="cointainer text-center m-5">
                      <Button type="submit" variant="success">
                        Submit
                      </Button>{' '}
                      {/* end row */}
                    </div>
                  </div>
                </form>
                {/* container */}
              </div>
            </Container>
          )}
        </div>
        {/* <ToastContainer autoClose={2000} /> */}
      </div>
    </div>
  );
}

export default EditTemplate;
