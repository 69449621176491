import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function TextAreaField({
  label,
  labelstyle,
  labeldarkstyle,
  fieldName,
  register,
  errors,
  placeHolder,
  isRequired,
  minimLength,
  mandatory,
  maximLength,
  resetCount,
}) {
  let classNames = labelstyle;

  if (localStorage.getItem('theme') === 'dark') {
    classNames = labeldarkstyle;
  }
  const [textAreaCount, ChangeTextAreaCount] = React.useState(0);
  useEffect(() => {
    if (resetCount) {
      ChangeTextAreaCount(0);
    }
  }, [resetCount]);
  return (
    <div className="form-field">
      <label htmlFor={fieldName} className={classNames ?? labelstyle}>
        {label}
      </label>
      {mandatory || isRequired ? <span style={{ color: 'red' }}>*</span> : <span />}
      <textarea
        rows="7"
        cols="80"
        placeholder={placeHolder ?? 'Type Here'}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register(fieldName, {
          onChange: (e) => {
            ChangeTextAreaCount(e.target.value.length);
          },
          required: {
            value: isRequired,
            message: `${fieldName} is required`,
          },
          minLength: {
            value: minimLength ?? 5,
            message: `Value must be minimum ${minimLength ?? 5} `,
          },
          maxLength: {
            value: maximLength,
            message: `${label} must be maximum ${maximLength}.`,
          },
        })}
      />
      {textAreaCount !== 0 && (
        <p style={{ float: 'right', fontSize: '12px' }}>
          {'Characters Left : '}
          {maximLength - textAreaCount}
        </p>
      )}
      <p style={{ color: 'red' }}>
        {' '}
        {
          // Shows if error exist
          errors[fieldName] && errors[fieldName].message
        }{' '}
      </p>
    </div>
  );
}

TextAreaField.propTypes = {
  fieldName: PropTypes.element.isRequired,
  labelstyle: PropTypes.element.isRequired,
  label: PropTypes.element,
  register: PropTypes.element.isRequired,
  errors: PropTypes.element.isRequired,
  placeHolder: PropTypes.element,
  isRequired: PropTypes.element,
  minimLength: PropTypes.element,
  mandatory: PropTypes.element,
  labeldarkstyle: PropTypes.element,
  maximLength: PropTypes.element,
  resetCount: PropTypes.bool,
};
TextAreaField.defaultProps = {
  mandatory: false,
  isRequired: false,
  labeldarkstyle: null,
  label: 'Text Area',
  placeHolder: 'Enter Value',
  minimLength: 100,
  maximLength: 500,
  resetCount: false,
};
export default TextAreaField;
