/* eslint-disable global-require */
import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
// import { SpinnerDotted } from 'spinners-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
// import { getCookies } from '../../hooks/useCookies';
import { TableContext } from '../../contexts/tableContext';
import styles from './view.module.scss';
import envValues from '../../enviornment';
import './view.css';
import 'react-quill/dist/quill.snow.css';
import 'react-toastify/dist/ReactToastify.css';

function CmsCreate() {
  const navigate = useNavigate();
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  // const { names, setNames } = useContext(TableContext);
  // const token = `Bearer ${getCookies('Token')}`;
  const [value, setValue] = useState();
  const [nameError, setNameError] = useState();
  const [aliasError, setAliasError] = useState();
  const [contentError, setContentError] = useState();
  const [alias, setAlias] = useState();
  const [names, setNames] = useState();
  const [contentValue, setContentValue] = useState();

  const inValid = /^\S.*[a-zA-Z\s]*$/;
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'],
    ],
  };
  const validateName = () => {
    if (!names || !inValid.test(names)) {
      setNameError('Name is required');
    } else {
      setNameError('');
    }
  };

  const validateAlias = () => {
    if (!alias || !inValid.test(alias)) {
      setAliasError('Alias is required');
    } else {
      setAliasError('');
    }
  };

  const validateContent = () => {
    if (!value || contentValue === 0 || !inValid.test(value)) {
      setContentError('Content is required');
    } else {
      setContentError('');
    }
  };

  const rteChange = (content, delta, source, editor) => {
    setValue(editor.getHTML());
    setContentValue(editor.getText().length);
    if (editor.getText().trim() === '') {
      setValue('');
    }
  };

  const convertQuillStylesToCss = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const quillClasses = ['ql-align-right', 'ql-align-center', 'ql-align-justify'];

    quillClasses.forEach((quillClass) => {
      // eslint-disable-next-line prefer-template
      const elements = doc.querySelectorAll('.' + quillClass);
      elements.forEach((element) => {
        element.classList.remove(quillClass);
        element.style.textAlign = quillClass.replace('ql-align-', '');
      });
    });
    const ulElements = doc.querySelectorAll('ul');
    ulElements.forEach((ul) => {
      ul.setAttribute('style', 'list-style-type: disc');
    });

    const olElements = doc.querySelectorAll('ol');
    olElements.forEach((ol) => {
      ol.setAttribute('style', 'type: 1');
    });

    return doc.body.innerHTML;
  };

  const onSubmitData = async (e) => {
    e.preventDefault();
    if (!names || !alias || !value) {
      validateName();
      validateAlias();
      validateContent();
    } else {
      const convertedContent = convertQuillStylesToCss(value);
      const apiData = {
        name: names,
        alias,
        content: convertedContent,
      };
      const res = await axios.post(`${envValues.REACT_APP_API_ENDPOINT}/cms`, apiData);
      if (res.data.success) {
        navigate(-1);
        toast.success('Cms creation successfull');
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Create Cms');
  }, []);

  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ height: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.empdetailssection}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          {/* {loading ? (
            <SpinnerDotted
              style={{
                left: '50%',
                position: 'relative',
                textAlign: 'center',
                top: '50%',
                color: '#fe0001',
              }}
            />
          ) : ( */}
          <Container fluid>
            <div className="content">
              <form onSubmit={onSubmitData}>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card-box task-detail">
                        <ul className="list-inline task-dates m-b-0 mt-5">
                          <li className="">
                            <h5 className="m-b-5 mx-1">Name</h5>
                            <div className="form-group mx-1">
                              <input
                                className="form-control"
                                name="names"
                                //  eslint-disable-next-line
                                onKeyUp={validateName}
                                value={names}
                                onChange={(e) => setNames(e.target.value)}
                                isRequired
                                mandatory
                                label="name"
                              />
                            </div>
                            {nameError && <small className="text-danger">{nameError}</small>}
                          </li>
                          <li>
                            <h5 className="m-b-5 mx-1">Alias</h5>
                            <div className="form-group mx-1">
                              <input
                                className="form-control"
                                name="alias"
                                //  eslint-disable-next-line
                                isRequired
                                onKeyUp={validateAlias}
                                mandatory
                                label="alias"
                                value={alias}
                                onChange={(e) => setAlias(e.target.value)}
                              />
                            </div>
                            {aliasError && <small className="text-danger">{aliasError}</small>}
                          </li>
                        </ul>

                        <div className="clearfix" />
                      </div>
                    </div>
                    {/* end col */}
                    {/* end col */}
                    <div className="col-lg-12">
                      <h5 className="m-b-5 mx-4">Content</h5>
                      <ReactQuill
                        className="mx-4"
                        theme="snow"
                        modules={modules}
                        onKeyUp={validateContent}
                        value={value}
                        onChange={rteChange}
                      />
                    </div>
                    {contentError && <small className="text-danger">{contentError}</small>}
                  </div>
                  <div className="cointainer text-center m-3">
                    <Button type="submit" variant="success">
                      Submit
                    </Button>{' '}
                    {/* end row */}
                  </div>
                </div>
              </form>
              {/* container */}
            </div>
          </Container>
          {/* )} */}
        </div>
        <ToastContainer autoClose={2000} />
      </div>
    </div>
  );
}

export default CmsCreate;
