import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from 'react-bootstrap/ProgressBar';

function WithLabelExample({ percentage }) {
  // eslint-disable-next-line react/react-in-jsx-scope
  return <ProgressBar animated now={percentage} label={`${percentage}%`} />;
}

WithLabelExample.propTypes = {
  percentage: PropTypes.number,
};

WithLabelExample.defaultProps = {
  percentage: 0,
};

export default WithLabelExample;
