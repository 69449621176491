import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';

const axiosInterceptor = axios.create({});

// Response interceptor
axiosInterceptor.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle unauthorized errors (e.g., redirect to login)
    confirmAlert({
      title: '',
      message:
        "It appears there's a disruption in the internet connection. Please refresh the page for uninterrupted service.",
      buttons: [
        {
          label: 'Refresh',
          onClick: () => {
            window.location.reload(false);
          },
        },
      ],
      closeOnClickOutside: false,
      closeOnEscape: false,
    });
    return Promise.reject(error);
  }
);

export default axiosInterceptor;
