/* eslint-disable import/no-mutable-exports */
const env = process.env.REACT_APP_ENV;
let envValues = {
  PORT: 80,
  REACT_APP_API_ENDPOINT: 'http://localhost:4075/v1',
  REACT_APP_API_FRONT_END: 'http://localhost:4075',
  REACT_APP_API_PERMISSION_DENIED: 'http://localhost:4075/permission',
  REACT_APP_SHOW_COOKIE_BOX: false,
  ERNIE_API_ENDPOINT: 'http://localhost:3000/v1',
  ERNIE_WEB_ADMIN_LOGIN: 'http://localhost:4200/login/admin-login',
  SENTRY_DNS:
    'https://7bf2fc667bc144d498eb078a75e1032c@o4505442314813440.ingest.sentry.io/4505465806585856',
};
switch (env) {
  case 'staging':
    envValues = {
      PORT: 80,
      REACT_APP_API_ENDPOINT: 'https://ernie-admin-api.iscriptsdemo.com/v1',
      REACT_APP_API_FRONT_END: 'https://ernie-admin-api.iscriptsdemo.com',
      REACT_APP_API_PERMISSION_DENIED: 'https://https://ernie-admin.iscriptsdemo.com/permission',
      REACT_APP_SHOW_COOKIE_BOX: false,
      ERNIE_API_ENDPOINT: 'https://ernie-api.iscriptsdemo.com/v1',
      ERNIE_WEB_ADMIN_LOGIN: 'https://ernie.iscriptsdemo.com/login/admin-login',
      SENTRY_DNS:
        'https://7bf2fc667bc144d498eb078a75e1032c@o4505442314813440.ingest.sentry.io/4505465806585856',
    };
    break;
  case 'production':
    envValues = {
      PORT: 80,
      REACT_APP_API_ENDPOINT: 'https://api-admin.ernie.com/v1',
      REACT_APP_API_FRONT_END: 'https://api-admin.ernie.com',
      REACT_APP_API_PERMISSION_DENIED: 'https://api-admin.ernie.com/permission',
      REACT_APP_SHOW_COOKIE_BOX: false,
      ERNIE_API_ENDPOINT: 'https://api.ernie.com/v1',
      ERNIE_WEB_ADMIN_LOGIN: 'https://ernie.com/login/admin-login',
      SENTRY_DNS:
        'https://7bf2fc667bc144d498eb078a75e1032c@o4505442314813440.ingest.sentry.io/4505465806585856',
    };
    break;
  case 'development':
    envValues = {
      PORT: 80,
      REACT_APP_API_ENDPOINT: 'http://localhost:4075/v1',
      REACT_APP_API_FRONT_END: 'http://localhost:4075',
      REACT_APP_API_PERMISSION_DENIED: 'http://localhost:4075/permission',
      REACT_APP_SHOW_COOKIE_BOX: false,
      ERNIE_API_ENDPOINT: 'http://localhost:3000/v1',
      ERNIE_WEB_ADMIN_LOGIN: 'http://localhost:4200/login/admin-login',
      SENTRY_DNS:
        'https://7bf2fc667bc144d498eb078a75e1032c@o4505442314813440.ingest.sentry.io/4505465806585856',
    };
    break;
  default:
    envValues = {
      PORT: 80,
      REACT_APP_API_ENDPOINT: 'http://localhost:4037/v1',
      REACT_APP_API_FRONT_END: 'http://localhost:4037',
      REACT_APP_API_PERMISSION_DENIED: 'http://localhost:3000/permission',
      REACT_APP_SHOW_COOKIE_BOX: false,
      ERNIE_API_ENDPOINT: 'http://localhost:3000/v1',
      ERNIE_WEB_ADMIN_LOGIN: 'http://localhost:4200/login/admin-login',
      SENTRY_DNS:
        'https://7bf2fc667bc144d498eb078a75e1032c@o4505442314813440.ingest.sentry.io/4505465806585856',
    };
    break;
}

export default envValues;
